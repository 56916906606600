import { AddIcon, LoginIcon, BellIcon, MenuIcon } from 'components/Icons';
import { Avatar } from 'components/Avatar';
import { Loader } from 'components/Loader';
import { MaxWidth } from 'components/MaxWidth';
import { MeQuery, useNotificationsQuery, useRemainingInvitesQuery } from 'generated/graphql';
import { NumberBubble } from 'components/NumberBubble';
import { useRouter } from 'next/router';
import { useUser } from 'components/UserProvider';
import Link from 'next/link';
import styles from './Header.module.css';
import { useState } from 'react';

const menuItems = [
  { label: 'Home', href: '/' },
  { label: 'Albums', href: '/albums' },
  { label: 'Tracks', href: '/tracks' },
];

export const Header = () => {
  const { asPath } = useRouter();
  const { user, userLoading } = useUser();
  const showLoader = !user && userLoading;

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className={styles.container}>
      <MaxWidth>
        <div className={styles.inner}>
          <div className={styles.logoMenuContainer}>
            <div className={styles.logoContainer}>
              <Link href="/" aria-label="Logo">
                <Logo />
              </Link>
            </div>

            <button
              aria-label="Toggle Menu"
              className={[styles.button, styles.menuButton].join(' ')}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <MenuIcon />
            </button>

            <div className={[styles.menu, menuOpen ? styles.isOpen : undefined].filter(Boolean).join(' ')}>
              {menuItems.map(({ label, href }) => (
                <Link
                  key={href}
                  href={href}
                  className={isPathActive(asPath, href) ? [styles.menuItem, styles.active].join(' ') : styles.menuItem}
                >
                  {label}
                </Link>
              ))}
            </div>
          </div>
          <div className={styles.buttonContainer}>
            {showLoader ? (
              <div className={styles.loaderContainer}>
                <Loader />
              </div>
            ) : !!user ? (
              <LoggedInUserMenu user={user} />
            ) : (
              <LoggedOutUserMenu />
            )}
          </div>
        </div>
      </MaxWidth>
    </header>
  );
};

const NotificationMenuButton = () => {
  const { data } = useNotificationsQuery();

  return (
    <Link href="/notifications" className={styles.button} aria-label="Notifications">
      <NumberBubble amount={data?.notifications?.length}>
        <BellIcon />
      </NumberBubble>
    </Link>
  );
};

const LoggedInUserMenu = ({ user }: { user: MeQuery['me'] }) => {
  const { data } = useRemainingInvitesQuery();

  return (
    <>
      <Link href="/posts/add" className={styles.button} aria-label="Add">
        <AddIcon />
      </Link>
      <NotificationMenuButton />
      <Link href="/account" className={styles.avatarButton} aria-label="Account">
        <NumberBubble amount={data?.remainingInvites}>
          <Avatar alt={user?.username} />
        </NumberBubble>
      </Link>
    </>
  );
};

const LoggedOutUserMenu = () => (
  <Link href="/login" aria-label="Login" className={styles.button}>
    <LoginIcon />
  </Link>
);

const Logo = () => (
  <svg width="130" height="15" viewBox="0 0 130 15" fill="#D5EE3E" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.92 10.9629V14.6172H0V10.9629H4.00232V4.61137C3.52668 5.0058 2.98144 5.27262 2.36659 5.41183C1.76334 5.53944 1.09049 5.60325 0.348028 5.60325V1.56613C1.68213 1.56613 2.90023 1.04408 4.00232 0H8.82251V10.9629H11.92Z" />
    <path d="M19.5483 15C17.3325 15 15.6852 14.5708 14.6063 13.7123C13.5274 12.8538 13.0054 11.5603 13.0402 9.83179H17.3209C17.4021 10.319 17.6341 10.6787 18.017 10.9107C18.4114 11.1311 18.9102 11.2413 19.5135 11.2413C20.3256 11.2413 20.9288 11.0673 21.3232 10.7193C21.7293 10.3712 21.9323 9.93619 21.9323 9.41415C21.9323 8.88051 21.7409 8.45708 21.358 8.14385C20.9868 7.81903 20.4242 7.65661 19.6701 7.65661C19.0785 7.65661 18.6144 7.74942 18.278 7.93503C17.9416 8.10905 17.6573 8.32947 17.4253 8.59629H13.1794L14.8325 0H25.2037V3.63689H18.3998L17.9822 5.62065C18.2606 5.42343 18.655 5.25522 19.1655 5.11601C19.6759 4.9768 20.314 4.90719 21.0796 4.90719C22.0657 4.90719 22.9648 5.08701 23.7768 5.44664C24.5889 5.79466 25.2327 6.32831 25.7084 7.04756C26.1956 7.76682 26.4392 8.64269 26.4392 9.67517C26.4392 11.3457 25.8476 12.6508 24.6643 13.5905C23.4926 14.5302 21.7873 15 19.5483 15Z" />
    <path d="M42.7269 4.01972H37.4717V14.6172H32.6515V4.01972H27.3963V0H42.7269V4.01972Z" />
    <path d="M43.5973 0H48.4871L50.0532 9.46636H50.4708L52.559 0H56.3873L58.4755 9.46636H58.8931L60.4592 0H65.349L62.5126 14.6172H56.0045L54.682 7.58701H54.2643L52.9418 14.6172H46.4337L43.5973 0Z" />
    <path d="M66.3102 0H79.483V3.86311H71.1304V5.72506H79.1698V8.89211H71.1304V10.7541H79.483V14.6172H66.3102V0Z" />
    <path d="M80.7071 0H86.2408L91.0958 7.90023H91.5308V0H96.2292V14.6172H90.8173L85.8405 6.71694H85.4055V14.6172H80.7071V0Z" />
    <path d="M112.693 4.01972H107.438V14.6172H102.617V4.01972H97.3622V0H112.693V4.01972Z" />
    <path d="M123.82 10.0754V14.6172H119V10.058L113.032 0H118.095L121.228 5.49884H121.61L124.743 0H129.807L123.82 10.0754Z" />
  </svg>
);

function isPathActive(asPath: string, href: string) {
  return href === '/' ? asPath === href : asPath.includes(href);
}
