import Image from 'next/legacy/image';
import styles from './Avatar.module.css';

interface Properties {
  alt?: string;
  src?: string;
}

export const Avatar = ({ alt = '', src }: Properties) => (
  <div className={styles.avatarContainer}>
    {src ? <Image alt={alt} height={20} src={src} width={20} /> : <span title={alt}>{alt.charAt(0)}</span>}
  </div>
);
