import { ReactNode } from 'react';

export const AddIcon = () => (
  <SVG>
    <path d="M9.99992 2.3335C9.53967 2.3335 9.16658 2.7066 9.16658 3.16683V8.16683H4.16659C3.70635 8.16683 3.33325 8.53991 3.33325 9.00016C3.33325 9.46041 3.70635 9.8335 4.16659 9.8335H9.16658V14.8335C9.16658 15.2937 9.53967 15.6668 9.99992 15.6668C10.4602 15.6668 10.8333 15.2937 10.8333 14.8335V9.8335H15.8333C16.2935 9.8335 16.6666 9.46041 16.6666 9.00016C16.6666 8.53991 16.2935 8.16683 15.8333 8.16683H10.8333V3.16683C10.8333 2.7066 10.4602 2.3335 9.99992 2.3335Z" />
  </SVG>
);

export const ArrowIcon = () => (
  <SVG size={24}>
    <path d="M18.254 11.745l-9.937 9.938a.824.824 0 01-.603.255.824.824 0 01-.602-.255L4.888 19.46a.824.824 0 01-.254-.603c0-.232.085-.433.254-.602L12 11.143 4.888 4.03a.824.824 0 01-.254-.602c0-.233.085-.433.254-.603L7.112.603c.17-.17.37-.255.602-.255.232 0 .433.085.603.255l9.937 9.937c.17.17.255.37.255.603a.823.823 0 01-.255.602z" />
  </SVG>
);

export const BellIcon = () => (
  <SVG size={24}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 3V3.28988C16.8915 4.15043 19 6.82898 19 10V17H20V19H4V17H5V10C5 6.82898 7.10851 4.15043 10 3.28988V3C10 1.89543 10.8954 1 12 1C13.1046 1 14 1.89543 14 3ZM7 17H17V10C17 7.23858 14.7614 5 12 5C9.23858 5 7 7.23858 7 10V17ZM14 21V20H10V21C10 22.1046 10.8954 23 12 23C13.1046 23 14 22.1046 14 21Z"
    />
  </SVG>
);

export const LoginIcon = () => (
  <SVG>
    <path d="M15.4857 20H19.4857C20.5903 20 21.4857 19.1046 21.4857 18V6C21.4857 4.89543 20.5903 4 19.4857 4H15.4857V6H19.4857V18H15.4857V20Z" />
    <path d="M10.1582 17.385L8.73801 15.9768L12.6572 12.0242L3.51428 12.0242C2.96199 12.0242 2.51428 11.5765 2.51428 11.0242C2.51429 10.4719 2.962 10.0242 3.51429 10.0242L12.6765 10.0242L8.69599 6.0774L10.1042 4.6572L16.4951 10.9941L10.1582 17.385Z" />
  </SVG>
);

export const PauseIcon = () => (
  <SVG size={24}>
    <path d="M9 9H11V15H9V9Z" />
    <path d="M15 15H13V9H15V15Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
    />
  </SVG>
);

export const PlayIcon = () => (
  <SVG>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99992 17.4999C14.1421 17.4999 17.4999 14.1421 17.4999 9.99992C17.4999 5.85778 14.1421 2.49992 9.99992 2.49992C5.85778 2.49992 2.49992 5.85778 2.49992 9.99992C2.49992 14.1421 5.85778 17.4999 9.99992 17.4999ZM9.99992 19.1666C15.0625 19.1666 19.1666 15.0625 19.1666 9.99992C19.1666 4.93731 15.0625 0.833252 9.99992 0.833252C4.93731 0.833252 0.833252 4.93731 0.833252 9.99992C0.833252 15.0625 4.93731 19.1666 9.99992 19.1666Z"
    />
    <path d="M13.3333 10L8.33325 13.6085V6.3916L13.3333 10Z" />
  </SVG>
);

export const SearchIcon = () => (
  <SVG>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2658 12.0272C17.3023 9.41179 17.1183 5.62793 14.714 3.22362C12.1105 0.620123 7.88937 0.620123 5.28588 3.22362C2.68238 5.82712 2.68238 10.0482 5.28588 12.6517C7.69019 15.056 11.4741 15.24 14.0895 13.2035C14.1007 13.2163 14.1125 13.2288 14.1247 13.241L17.6603 16.7765C17.9857 17.102 18.5133 17.102 18.8387 16.7765C19.1642 16.451 19.1642 15.9235 18.8387 15.598L15.3033 12.0625C15.291 12.0503 15.2785 12.0385 15.2658 12.0272ZM13.5354 4.40213C15.4881 6.35475 15.4881 9.52054 13.5354 11.4732C11.5828 13.4258 8.417 13.4258 6.46439 11.4732C4.51176 9.52054 4.51176 6.35475 6.46439 4.40213C8.417 2.44951 11.5828 2.44951 13.5354 4.40213Z"
    />
  </SVG>
);

export const SpinnerIcon = () => (
  <SVG size={24}>
    <path
      clipRule="evenodd"
      d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fillRule="evenodd"
      opacity="0.2"
    />
    <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z" />
  </SVG>
);

export const UpIcon = () => (
  <SVG size={24}>
    <path d="M17.6569 16.2427L19.0711 14.8285L12.0001 7.75739L4.92896 14.8285L6.34317 16.2427L12.0001 10.5858L17.6569 16.2427Z" />
  </SVG>
);

export const TrashIcon = () => (
  <SVG size={24}>
    <path
      clipRule="evenodd"
      d="M17 5V4C17 2.89543 16.1046 2 15 2H9C7.89543 2 7 2.89543 7 4V5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H5V18C5 19.6569 6.34315 21 8 21H16C17.6569 21 19 19.6569 19 18V7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H17ZM15 4H9V5H15V4ZM17 7H7V18C7 18.5523 7.44772 19 8 19H16C16.5523 19 17 18.5523 17 18V7Z"
      fillRule="evenodd"
    />
    <path d="M9 9H11V17H9V9Z" />
    <path d="M13 9H15V17H13V9Z" />
  </SVG>
);

export const MenuIcon = () => (
  <SVG size={24}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" stroke="currentColor" />
  </SVG>
);

interface Properties {
  children: ReactNode;
  size?: number;
}

const SVG = ({ children, size = 20 }: Properties) => (
  <svg
    width={size.toString()}
    height={size.toString()}
    viewBox={`0 0 ${size} ${size}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    {children}
  </svg>
);
