import { SpinnerIcon } from 'components/Icons';
import styles from './Loader.module.css';

interface Properties {
  alignCenter?: boolean;
}

export const Loader = ({ alignCenter }: Properties) => (
  <span className={[styles.loader, alignCenter ? styles.alignCenter : undefined].filter(Boolean).join(' ')}>
    <SpinnerIcon />
  </span>
);
