import { ReactNode } from 'react';
import styles from './NumberBubble.module.css';

interface Properties {
  amount?: number;
  children: ReactNode;
}

export const NumberBubble = ({ amount = 0, children }: Properties) => (
  <div className={styles.container}>
    {children}
    {amount > 0 && <div className={styles.bubble}>{amount}</div>}
  </div>
);
