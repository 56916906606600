import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { Loader } from 'components/Loader';
import { MaxWidth } from './MaxWidth';
import { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useUser } from 'components/UserProvider';
import Head from 'next/head';
import styles from './Page.module.css';

const defaults = {
  description: '15Twenty is a community of hiphop fanatics, discovering the best of the industry.',
  image: 'https://15twenty.co/og-image.png ',
  title: '15Twenty - Discover the best in hiphop',
};

interface Properties {
  children?: ReactNode;
  description?: string;
  image?: string;
  loading?: boolean;
  needsAuth?: boolean;
  needsNoAuth?: boolean;
  title?: string;
}

export const Page = ({ children, description, image, loading, title, needsAuth, needsNoAuth }: Properties) => {
  const needsAuthCheck = !!(needsAuth || needsNoAuth);
  const { user, userLoading } = useUser();
  const router = useRouter();
  const showLoader = (needsAuthCheck && !user && userLoading) || loading;

  useEffect(() => {
    if (needsAuthCheck && !userLoading && (needsAuth ? !user : user)) {
      router.push('/');
    }
  }, [needsAuth, needsAuthCheck, router, user, userLoading]);

  return (
    <div className={styles.container}>
      <Head>
        <title>{title || defaults.title}</title>
        <meta name="title" content={title || defaults.title} />
        <meta property="og:title" content={title || defaults.title} />
        <meta name="description" content={description || defaults.description} />
        <meta property="og:description" content={description || defaults.description} />
        <meta property="og:image" content={image || defaults.image} />
        <meta property="og:type" content="website" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:creator" content="@dennispassway" />
        <meta property="twitter:title" content={title || defaults.title} />
        <meta property="twitter:description" content={description || defaults.description} />
        <meta property="twitter:image" content={image || defaults.image} />
      </Head>

      <Header />
      <div className={styles.childrenContainer}>
        {showLoader ? (
          <MaxWidth>
            <Loader alignCenter />
          </MaxWidth>
        ) : (
          children
        )}
      </div>
      <Footer />
    </div>
  );
};
